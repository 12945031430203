// App - Types
export type { IconProps } from './icon';

// App - Other
export { Icon } from './icon';
export { AddIcon } from './add-icon';
export { AirtableIcon } from './airtable-icon';
export { AlertIcon } from './alert-icon';
export { AngularIcon } from './angular-icon';
export { APIChangeIcon } from './api-change-icon';
export { ArchitectureIcon } from './architecture-icon';
export { ArchiveIcon } from './archive-icon';
export { ArrowDownIcon } from './arrow-down-icon';
export { ArrowIcon } from './arrow-icon';
export { ArrowLeftFilledIcon } from './arrow-left-filled-icon';
export { ArrowLeftIcon } from './arrow-left-icon';
export { ArrowRightFilledIcon } from './arrow-right-filled-icon';
export { ArrowRightIcon } from './arrow-right-icon';
export { ArrowTrendDownIcon } from './arrow-trend-down-icon';
export { ArrowTrendUpIcon } from './arrow-trend-up-icon';
export { ArrowUpIcon } from './arrow-up-icon';
export { AsanaIcon } from './asana-icon';
export { AssetsIcon } from './assets-icon';
export { AttachmentsIcon } from './attachments-icon';
export { AuthenticationIcon } from './authentication-icon';
export { AuthorizationIcon } from './authorization-icon';
export { AwsIcon } from './aws-icon';
export { AzureDevOpsIcon } from './azure-devops-icon';
export { AzureDevOpsRepositoryIcon } from './azure-devops-repository-icon';
export { AzureDevOpsWikiIcon } from './azure-devops-wiki-icon';
export { AzureDevOpsWorkItemIcon } from './azure-devops-work-item-icon';
export { AzureIcon } from './azure-icon';
export { BasecampIcon } from './basecamp-icon';
export { BeatIcon } from './beat-icon';
export { BellIcon } from './bell-icon';
export { BinaryTreeIcon } from './binary-tree-icon';
export { BitbucketIcon } from './bitbucket-icon';
export { BuildingIcon } from './building-icon';
export { BulbIcon } from './bulb-icon';
export { BusinessLogicIcon } from './business-logic-icon';
export { CIcon } from './c-icon';
export { CalculatorIcon } from './calculator-icon';
export { CalendarIcon } from './calendar-icon';
export { CancelIcon } from './cancel-icon';
export { CancelOutlineIcon } from './cancel-outline-icon';
export { CanvaIcon } from './canva-icon';
export { CheckIcon } from './check-icon';
export { CheckOutlineIcon } from './check-outline-icon';
export { ChevronDownIcon } from './chevron-down-icon';
export { ChevronLeftIcon } from './chevron-left-icon';
export { ChevronRightIcon } from './chevron-right-icon';
export { CircleIcon } from './circle-icon';
export { CircleOutlineIcon } from './circle-outline-icon';
export { CisIcon } from './cis-icon';
export { ClassificationsIcon } from './classifications-icon';
export { ClearAllIcon } from './clear-all-icon';
export { CloseIcon } from './close-icon';
export { ClipboardIcon } from './clipboard-icon';
export { ClockIcon } from './clock-icon';
export { CloudIcon } from './cloud-icon';
export { CloverLogoIcon } from './clover-logo-icon';
export { CloverLogoWithTextIcon } from './clover-logo-with-text-icon';
export { CloverOldLogoIcon } from './clover-old-logo-icon';
export { CodeIcon } from './code-icon';
export { ColorableAwsIcon } from './colorable-aws-icon';
export { ColorableCloverLogoIcon } from './colorable-clover-logo-icon';
export { ColorableConfluenceIcon } from './colorable-confluence-icon';
export { ColorableGoogleDriveIcon } from './colorable-google-drive-icon';
export { ColorableJiraIcon } from './colorable-jira-icon';
export { ColumnsIcon } from './columns-icon';
export { CommentIcon } from './comment-icon';
export { CommitIcon } from './commit-icon';
export { ConfluenceIcon } from './confluence-icon';
export { ContentCopyIcon } from './content-copy-icon';
export { CPPIcon } from './cpp-icon';
export { CryptographyIcon } from './cryptography-icon';
export { CSharpIcon } from './csharp-icon';
export { CSSIcon } from './css-icon';
export { DartIcon } from './dart-icon';
export { DataExchangeIcon } from './data-exchange-icon';
export { DeleteIcon } from './delete-icon';
export { DenialOfServiceIcon } from './denial-of-service-icon';
export { DensityLargeIcon } from './density-large-icon';
export { DensityMediumIcon } from './density-medium-icon';
export { DensitySmallIcon } from './density-small-icon';
export { DesignDecisionIcon } from './design-decision-icon';
export { DockerIcon } from './docker-icon';
export { DocumentIcon } from './document-icon';
export { DotsHorizontalIcon } from './dots-horizontal-icon';
export { DotsHorizontalOutlineIcon } from './dots-horizontal-outline-icon';
export { DotsVerticalIcon } from './dots-vertical-icon';
export { DragHandleIcon } from './drag-handle-icon';
export { DrawioIcon } from './drawio-icon';
export { DropboxIcon } from './dropbox-icon';
export { DumbleIcon } from './dumble-icon';
export { EditIcon } from './edit-icon';
export { ElevationOfPrivilegeIcon } from './elevation-of-privilege-icon';
export { ErrorIcon } from './error-icon';
export { ExpandAllIcon } from './expand-all-icon';
export { ExternalLinkIcon } from './external-link-icon';
export { EyeIcon } from './eye-icon';
export { EyeOffIcon } from './eye-off-icon';
export { FedRampIcon } from './fed-ramp-icon';
export { FiberyIcon } from './fibery-icon';
export { FileIcon } from './file-icon';
export { FilledCheckSquareIcon } from './filled-check-square-icon';
export { FilledClipboardIcon } from './filled-clipboard-icon';
export { FilledFlashIcon } from './filled-flash-icon';
export { FigmaIcon } from './figma-icon';
export { FilterFunnelIcon } from './filter-funnel-icon';
export { FilterIcon } from './filter-icon';
export { FilterOffIcon } from './filter-off-icon';
export { FingerprintIcon } from './fingerprint-icon';
export { FirstPageIcon } from './first-page-icon';
export { FlashIcon } from './flash-icon';
export { FrameworkIcon } from './framework-icon';
export { GenAIIcon } from './gen-ai-icon';
export { GithubIcon } from './github-icon';
export { GitlabIcon } from './gitlab-icon';
export { GmailIcon } from './gmail-icon';
export { GoIcon } from './go-icon';
export { GoogleCloudIcon } from './google-cloud-icon';
export { GoogleDriveIcon } from './google-drive-icon';
export { GrafanaIcon } from './grafana-icon';
export { GranularIcon } from './granular-icon';
export { GraphIcon } from './graph-icon';
export { GridIcon } from './grid-icon';
export { GroovyIcon } from './groovy-icon';
export { GroupByIcon } from './group-by-icon';
export { HoverIcon } from './hover-icon';
export { HTMLIcon } from './html-icon';
export { IecIcon } from './iec-icon';
export { ImageFileIcon } from './image-file-icon';
export { InfoDisclosureIcon } from './info-disclosure-icon';
export { InfoIcon } from './info-icon';
export { InfrastructureIcon } from './infrastructure-icon';
export { InputFlowIcon } from './input-flow-icon';
export { JavaIcon } from './java-icon';
export { JavaScriptIcon } from './javascript-icon';
export { JiraBugIcon } from './jira-bug-icon';
export { JiraEpicIcon } from './jira-epic-icon';
export { JiraIcon } from './jira-icon';
export { JiraStoryIcon } from './jira-story-icon';
export { JiraTaskIcon } from './jira-task-icon';
export { JsonIcon } from './json-icon';
export { KeyIcon } from './key-icon';
export { KibanaIcon } from './kibana-icon';
export { KotlinIcon } from './kotlin-icon';
export { KubernetesIcon } from './kubernetes-icon';
export { LastPageIcon } from './last-page-icon';
export { LayersIcon } from './layers-icon';
export { LinearIcon } from './linear-icon';
export { LinkIcon } from './link-icon';
export { LocateIcon } from './locate-icon';
export { LockIcon } from './lock-icon';
export { LookerIcon } from './looker-icon';
export { LuaIcon } from './lua-icon';
export { LucidchartIcon } from './lucidchart-icon';
export { MarkdownIcon } from './markdown-icon';
export { MatlabIcon } from './matlab-icon';
export { MattermostIcon } from './mattermost-icon';
export { MicrosoftExcelIcon } from './microsoft-excel-icon';
export { MicrosoftWordIcon } from './microsoft-word-icon';
export { MinimizeIcon } from './minimize-icon';
export { MinusIcon } from './minus-icon';
export { MiroIcon } from './miro-icon';
export { ModifiedIcon } from './modified-icon';
export { MondayIcon } from './monday-icon';
export { MoonIcon } from './moon-icon';
export { NistIcon } from './nist-icon';
export { HitrustIcon } from './hitrust-icon';
export { NotionIcon } from './notion-icon';
export { ObjectiveCIcon } from './objective-c-icon';
export { OneDriveIcon } from './onedrive-icon';
export { OutlineInfoIcon } from './outline-info-icon';
export { OutlineQuestionIcon } from './outline-question-icon';
export { OwaspIcon } from './owasp-icon';
export { PackageFocusIcon } from './package-focus-icon';
export { PackageIcon } from './package-icon';
export { PagerDutyIcon } from './pager-duty-icon';
export { PciIcon } from './pci-icon';
export { PencilOutlineIcon } from './pencil-outline-icon';
export { PerlIcon } from './perl-icon';
export { PhpIcon } from './php-icon';
export { PIIIcon } from './pii-icon';
export { PlayPauseIcon } from './play-pause-icon';
export { PlusIcon } from './plus-icon';
export { PostmanIcon } from './postman-icon';
export { PowerPointIcon } from './power-point-icon';
export { PowerShellIcon } from './power-shell-icon';
export { PullRequestIcon } from './pull-request-icon';
export { PushPinIcon } from './push-pin-icon';
export { PythonIcon } from './python-icon';
export { QuipIcon } from './quip-icon';
export { RIcon } from './r-icon';
export { ReactIcon } from './react-icon';
export { RecommendationIcon } from './recommendation-icon';
export { RefreshIcon } from './refresh-icon';
export { ReloadIcon } from './reload-icon';
export { RemoveIcon } from './remove-icon';
export { RepositoryIcon } from './repository-icon';
export { RepudiationIcon } from './repudiation-icon';
export { RequirementsAnalysisIcon } from './requirements-analysis-icon';
export { ResuggestIcon } from './resuggest-icon';
export { ReverseArrowIcon } from './reverse-arrow-icon';
export { RubyIcon } from './ruby-icon';
export { RustIcon } from './rust-icon';
export { SalesforceIcon } from './salesforce-icon';
export { SaveIcon } from './save-icon';
export { ScalaIcon } from './scala-icon';
export { ScanIcon } from './scan-icon';
export { SearchIcon } from './search-icon';
export { SearchOffIcon } from './search-off-icon';
export { SecurityAnalysisIcon } from './security-analysis-icon';
export { SendIcon } from './send-icon';
export { SendTiltDownwardsIcon } from './send-tilt-downwards-icon';
export { SendTiltUpwardsIcon } from './send-tilt-upwards-icon';
export { SensitiveInfoIcon } from './sensitive-info-icon';
export { ServerIcon } from './server-icon';
export { ServiceNowIcon } from './service-now-icon';
export { ServiceTitanIcon } from './service-titan-icon';
export { SettingsIcon } from './settings-icon';
export { SharePointIcon } from './share-point-icon';
export { ShellIcon } from './shell-icon';
export { ShieldIcon } from './shield-icon';
export { SlackIcon } from './slack-icon';
export { SmartsheetIcon } from './smartsheet-icon';
export { SortIcon } from './sort-icon';
export { SpeedometerIcon } from './speedometer-icon';
export { SpoofingIcon } from './spoofing-icon';
export { SQLIcon } from './sql-icon';
export { SuccessIcon } from './success-icon';
export { SunIcon } from './sun-icon';
export { SwapHorizontalIcon } from './swap-horizontal-icon';
export { SwiftIcon } from './swift-icon';
export { TableauIcon } from './tableau-icon';
export { TagsIcon } from './tags-icon';
export { TamperingIcon } from './tampering-icon';
export { TargetIcon } from './target-icon';
export { TeamsIcon } from './teams-icon';
export { TerraformIcon } from './terraform-icon';
export { TextIcon } from './text-icon';
export { ThumbsDownIcon } from './thumbs-down-icon';
export { ThumbsUpIcon } from './thumbs-up-icon';
export { TicketIcon } from './ticket-icon';
export { TomlIcon } from './toml-icon';
export { TrelloIcon } from './trello-icon';
export { TypeScriptIcon } from './typescript-icon';
export { UnknownIcon } from './unknown-icon';
export { UmbrellaIcon } from './umbrella-icon';
export { UsersIcon } from './users-icon';
export { ViolationAttentionIcon } from './violation-attention-icon';
export { VisualBasicIcon } from './visual-basic-icon';
export { VueIcon } from './vue-icon';
export { WarningIcon } from './warning-icon';
export { WarningOutlineIcon } from './warning-outline-icon';
export { WeaknessIcon } from './weakness-icon';
export { WhimsicalIcon } from './whimsical-icon';
export { YamlIcon } from './yaml-icon';
export { ZendeskIcon } from './zendesk-icon';
export { ZoomIcon } from './zoom-icon';

// Metadata
import { AddIconMetadata } from './add-icon';
import { AirtableIconMetadata } from './airtable-icon';
import { AlertIconMetadata } from './alert-icon';
import { AngularIconMetadata } from './angular-icon';
import { APIChangeIconMetadata } from './api-change-icon';
import { ArchitectureIconMetadata } from './architecture-icon';
import { ArchiveIconMetadata } from './archive-icon';
import { ArrowDownIconMetadata } from './arrow-down-icon';
import { ArrowIconMetadata } from './arrow-icon';
import { ArrowLeftFilledIconMetadata } from './arrow-left-filled-icon';
import { ArrowLeftIconMetadata } from './arrow-left-icon';
import { ArrowRightFilledIconMetadata } from './arrow-right-filled-icon';
import { ArrowRightIconMetadata } from './arrow-right-icon';
import { ArrowTrendDownIconMetadata } from './arrow-trend-down-icon';
import { ArrowTrendUpIconMetadata } from './arrow-trend-up-icon';
import { ArrowUpIconMetadata } from './arrow-up-icon';
import { AsanaIconMetadata } from './asana-icon';
import { AssetsIconMetadata } from './assets-icon';
import { AttachmentsIconMetadata } from './attachments-icon';
import { AuthenticationIconMetadata } from './authentication-icon';
import { AuthorizationIconMetadata } from './authorization-icon';
import { AwsIconMetadata } from './aws-icon';
import { AzureDevOpsIconMetadata } from './azure-devops-icon';
import { AzureDevOpsRepositoryIconMetadata } from './azure-devops-repository-icon';
import { AzureDevOpsWikiIconMetadata } from './azure-devops-wiki-icon';
import { AzureDevOpsWorkItemIconMetadata } from './azure-devops-work-item-icon';
import { AzureIconMetadata } from './azure-icon';
import { BasecampIconMetadata } from './basecamp-icon';
import { BeatIconMetadata } from './beat-icon';
import { BellIconMetadata } from './bell-icon';
import { BinaryTreeIconMetadata } from './binary-tree-icon';
import { BitbucketIconMetadata } from './bitbucket-icon';
import { BuildingIconMetadata } from './building-icon';
import { BulbIconMetadata } from './bulb-icon';
import { BusinessLogicIconMetadata } from './business-logic-icon';
import { CIconMetadata } from './c-icon';
import { CalculatorIconMetadata } from './calculator-icon';
import { CalendarIconMetadata } from './calendar-icon';
import { CancelIconMetadata } from './cancel-icon';
import { CancelOutlineIconMetadata } from './cancel-outline-icon';
import { CanvaIconMetadata } from './canva-icon';
import { CheckIconMetadata } from './check-icon';
import { CheckOutlineIconMetadata } from './check-outline-icon';
import { ChevronDownIconMetadata } from './chevron-down-icon';
import { ChevronLeftIconMetadata } from './chevron-left-icon';
import { ChevronRightIconMetadata } from './chevron-right-icon';
import { CircleIconMetadata } from './circle-icon';
import { CircleOutlineIconMetadata } from './circle-outline-icon';
import { CisIconMetadata } from './cis-icon';
import { ClassificationsIconMetadata } from './classifications-icon';
import { ClearAllIconMetadata } from './clear-all-icon';
import { CloseIconMetadata } from './close-icon';
import { ClipboardIconMetadata } from './clipboard-icon';
import { ClockIconMetadata } from './clock-icon';
import { CloudIconMetadata } from './cloud-icon';
import { CloverLogoIconMetadata } from './clover-logo-icon';
import { CloverLogoWithTextIconMetadata } from './clover-logo-with-text-icon';
import { CloverOldLogoIconMetadata } from './clover-old-logo-icon';
import { CodeIconMetadata } from './code-icon';
import { ColorableAwsIconMetadata } from './colorable-aws-icon';
import { ColorableCloverLogoIconMetadata } from './colorable-clover-logo-icon';
import { ColorableConfluenceIconMetadata } from './colorable-confluence-icon';
import { ColorableGoogleDriveIconMetadata } from './colorable-google-drive-icon';
import { ColorableJiraIconMetadata } from './colorable-jira-icon';
import { ColumnsIconMetadata } from './columns-icon';
import { CommentIconMetadata } from './comment-icon';
import { CommitIconMetadata } from './commit-icon';
import { ConfluenceIconMetadata } from './confluence-icon';
import { ContentCopyIconMetadata } from './content-copy-icon';
import { CPPIconMetadata } from './cpp-icon';
import { CryptographyIconMetadata } from './cryptography-icon';
import { CSharpIconMetadata } from './csharp-icon';
import { CSSIconMetadata } from './css-icon';
import { DartIconMetadata } from './dart-icon';
import { DataExchangeIconMetadata } from './data-exchange-icon';
import { DeleteIconMetadata } from './delete-icon';
import { DenialOfServiceIconMetadata } from './denial-of-service-icon';
import { DensityLargeIconMetadata } from './density-large-icon';
import { DensityMediumIconMetadata } from './density-medium-icon';
import { DensitySmallIconMetadata } from './density-small-icon';
import { DesignDecisionIconMetadata } from './design-decision-icon';
import { DockerIconMetadata } from './docker-icon';
import { DocumentIconMetadata } from './document-icon';
import { DotsHorizontalIconMetadata } from './dots-horizontal-icon';
import { DotsHorizontalOutlineIconMetadata } from './dots-horizontal-outline-icon';
import { DotsVerticalIconMetadata } from './dots-vertical-icon';
import { DragHandleIconMetadata } from './drag-handle-icon';
import { DrawioIconMetadata } from './drawio-icon';
import { DropboxIconMetadata } from './dropbox-icon';
import { DumbleIconMetadata } from './dumble-icon';
import { EditIconMetadata } from './edit-icon';
import { ElevationOfPrivilegeIconMetadata } from './elevation-of-privilege-icon';
import { ErrorIconMetadata } from './error-icon';
import { ExpandAllIconMetadata } from './expand-all-icon';
import { ExternalLinkIconMetadata } from './external-link-icon';
import { EyeIconMetadata } from './eye-icon';
import { EyeOffIconMetadata } from './eye-off-icon';
import { FedRampIconMetadata } from './fed-ramp-icon';
import { FiberyIconMetadata } from './fibery-icon';
import { FileIconMetadata } from './file-icon';
import { FilledCheckSquareIconMetadata } from './filled-check-square-icon';
import { FilledClipboardIconMetadata } from './filled-clipboard-icon';
import { FilledFlashIconMetadata } from './filled-flash-icon';
import { FigmaIconMetadata } from './figma-icon';
import { FilterFunnelIconMetadata } from './filter-funnel-icon';
import { FilterIconMetadata } from './filter-icon';
import { FilterOffIconMetadata } from './filter-off-icon';
import { FingerprintIconMetadata } from './fingerprint-icon';
import { FirstPageIconMetadata } from './first-page-icon';
import { FlashIconMetadata } from './flash-icon';
import { FrameworkIconMetadata } from './framework-icon';
import { GenAIIconMetadata } from './gen-ai-icon';
import { GithubIconMetadata } from './github-icon';
import { GitlabIconMetadata } from './gitlab-icon';
import { GmailIconMetadata } from './gmail-icon';
import { GoIconMetadata } from './go-icon';
import { GoogleCloudIconMetadata } from './google-cloud-icon';
import { GoogleDriveIconMetadata } from './google-drive-icon';
import { GrafanaIconMetadata } from './grafana-icon';
import { GranularIconMetadata } from './granular-icon';
import { GraphIconMetadata } from './graph-icon';
import { GridIconMetadata } from './grid-icon';
import { GroovyIconMetadata } from './groovy-icon';
import { GroupByIconMetadata } from './group-by-icon';
import { HoverIconMetadata } from './hover-icon';
import { HTMLIconMetadata } from './html-icon';
import { IecIconMetadata } from './iec-icon';
import { ImageFileIconMetadata } from './image-file-icon';
import { InfoDisclosureIconMetadata } from './info-disclosure-icon';
import { InfoIconMetadata } from './info-icon';
import { InfrastructureIconMetadata } from './infrastructure-icon';
import { InputFlowIconMetadata } from './input-flow-icon';
import { JavaIconMetadata } from './java-icon';
import { JavaScriptIconMetadata } from './javascript-icon';
import { JiraBugIconMetadata } from './jira-bug-icon';
import { JiraEpicIconMetadata } from './jira-epic-icon';
import { JiraIconMetadata } from './jira-icon';
import { JiraStoryIconMetadata } from './jira-story-icon';
import { JiraTaskIconMetadata } from './jira-task-icon';
import { JsonIconMetadata } from './json-icon';
import { KeyIconMetadata } from './key-icon';
import { KibanaIconMetadata } from './kibana-icon';
import { KotlinIconMetadata } from './kotlin-icon';
import { KubernetesIconMetadata } from './kubernetes-icon';
import { LastPageIconMetadata } from './last-page-icon';
import { LayersIconMetadata } from './layers-icon';
import { LinearIconMetadata } from './linear-icon';
import { LinkIconMetadata } from './link-icon';
import { LocateIconMetadata } from './locate-icon';
import { LockIconMetadata } from './lock-icon';
import { LookerIconMetadata } from './looker-icon';
import { LuaIconMetadata } from './lua-icon';
import { LucidchartIconMetadata } from './lucidchart-icon';
import { MarkdownIconMetadata } from './markdown-icon';
import { MatlabIconMetadata } from './matlab-icon';
import { MattermostIconMetadata } from './mattermost-icon';
import { MicrosoftExcelIconMetadata } from './microsoft-excel-icon';
import { MicrosoftWordIconMetadata } from './microsoft-word-icon';
import { MinimizeIconMetadata } from './minimize-icon';
import { MinusIconMetadata } from './minus-icon';
import { MiroIconMetadata } from './miro-icon';
import { ModifiedIconMetadata } from './modified-icon';
import { MondayIconMetadata } from './monday-icon';
import { MoonIconMetadata } from './moon-icon';
import { NistIconMetadata } from './nist-icon';
import { HitrustIconMetadata } from './hitrust-icon';
import { NotionIconMetadata } from './notion-icon';
import { ObjectiveCIconMetadata } from './objective-c-icon';
import { OneDriveIconMetadata } from './onedrive-icon';
import { OutlineInfoIconMetadata } from './outline-info-icon';
import { OutlineQuestionIconMetadata } from './outline-question-icon';
import { OwaspIconMetadata } from './owasp-icon';
import { PackageFocusIconMetadata } from './package-focus-icon';
import { PackageIconMetadata } from './package-icon';
import { PagerDutyIconMetadata } from './pager-duty-icon';
import { PciIconMetadata } from './pci-icon';
import { PencilOutlineIconMetadata } from './pencil-outline-icon';
import { PerlIconMetadata } from './perl-icon';
import { PhpIconMetadata } from './php-icon';
import { PIIIconMetadata } from './pii-icon';
import { PlayPauseIconMetadata } from './play-pause-icon';
import { PlusIconMetadata } from './plus-icon';
import { PostmanIconMetadata } from './postman-icon';
import { PowerPointIconMetadata } from './power-point-icon';
import { PowerShellIconMetadata } from './power-shell-icon';
import { PullRequestIconMetadata } from './pull-request-icon';
import { PushPinIconMetadata } from './push-pin-icon';
import { PythonIconMetadata } from './python-icon';
import { QuipIconMetadata } from './quip-icon';
import { RIconMetadata } from './r-icon';
import { ReactIconMetadata } from './react-icon';
import { RecommendationIconMetadata } from './recommendation-icon';
import { RefreshIconMetadata } from './refresh-icon';
import { ReloadIconMetadata } from './reload-icon';
import { RemoveIconMetadata } from './remove-icon';
import { RepositoryIconMetadata } from './repository-icon';
import { RepudiationIconMetadata } from './repudiation-icon';
import { RequirementsAnalysisIconMetadata } from './requirements-analysis-icon';
import { ResuggestIconMetadata } from './resuggest-icon';
import { ReverseArrowIconMetadata } from './reverse-arrow-icon';
import { RubyIconMetadata } from './ruby-icon';
import { RustIconMetadata } from './rust-icon';
import { SalesforceIconMetadata } from './salesforce-icon';
import { SaveIconMetadata } from './save-icon';
import { ScalaIconMetadata } from './scala-icon';
import { ScanIconMetadata } from './scan-icon';
import { SearchIconMetadata } from './search-icon';
import { SearchOffIconMetadata } from './search-off-icon';
import { SecurityAnalysisIconMetadata } from './security-analysis-icon';
import { SendIconMetadata } from './send-icon';
import { SendTiltDownwardsIconMetadata } from './send-tilt-downwards-icon';
import { SendTiltUpwardsIconMetadata } from './send-tilt-upwards-icon';
import { SensitiveInfoIconMetadata } from './sensitive-info-icon';
import { ServerIconMetadata } from './server-icon';
import { ServiceNowIconMetadata } from './service-now-icon';
import { ServiceTitanIconMetadata } from './service-titan-icon';
import { SettingsIconMetadata } from './settings-icon';
import { SharePointIconMetadata } from './share-point-icon';
import { ShellIconMetadata } from './shell-icon';
import { ShieldIconMetadata } from './shield-icon';
import { SlackIconMetadata } from './slack-icon';
import { SmartsheetIconMetadata } from './smartsheet-icon';
import { SortIconMetadata } from './sort-icon';
import { SpeedometerIconMetadata } from './speedometer-icon';
import { SpoofingIconMetadata } from './spoofing-icon';
import { SQLIconMetadata } from './sql-icon';
import { SuccessIconMetadata } from './success-icon';
import { SunIconMetadata } from './sun-icon';
import { SwapHorizontalIconMetadata } from './swap-horizontal-icon';
import { SwiftIconMetadata } from './swift-icon';
import { TableauIconMetadata } from './tableau-icon';
import { TagsIconMetadata } from './tags-icon';
import { TargetIconMetadata } from './target-icon';
import { TeamsIconMetadata } from './teams-icon';
import { TerraformIconMetadata } from './terraform-icon';
import { TamperingIconMetadata } from './tampering-icon';
import { TextIconMetadata } from './text-icon';
import { ThumbsDownIconMetadata } from './thumbs-down-icon';
import { ThumbsUpIconMetadata } from './thumbs-up-icon';
import { TicketIconMetadata } from './ticket-icon';
import { TomlIconMetadata } from './toml-icon';
import { TrelloIconMetadata } from './trello-icon';
import { TypeScriptIconMetadata } from './typescript-icon';
import { UnknownIconMetadata } from './unknown-icon';
import { UmbrellaIconMetadata } from './umbrella-icon';
import { UsersIconMetadata } from './users-icon';
import { ViolationAttentionIconMetadata } from './violation-attention-icon';
import { VisualBasicIconMetadata } from './visual-basic-icon';
import { VueIconMetadata } from './vue-icon';
import { WarningIconMetadata } from './warning-icon';
import { WarningOutlineIconMetadata } from './warning-outline-icon';
import { WeaknessIconMetadata } from './weakness-icon';
import { WhimsicalIconMetadata } from './whimsical-icon';
import { YamlIconMetadata } from './yaml-icon';
import { ZendeskIconMetadata } from './zendesk-icon';
import { ZoomIconMetadata } from './zoom-icon';

export const iconsMetaData = [
  AddIconMetadata,
  AirtableIconMetadata,
  AlertIconMetadata,
  AngularIconMetadata,
  APIChangeIconMetadata,
  ArchitectureIconMetadata,
  ArchiveIconMetadata,
  ArrowDownIconMetadata,
  ArrowIconMetadata,
  ArrowLeftFilledIconMetadata,
  ArrowLeftIconMetadata,
  ArrowRightFilledIconMetadata,
  ArrowRightIconMetadata,
  ArrowTrendDownIconMetadata,
  ArrowTrendUpIconMetadata,
  ArrowUpIconMetadata,
  AsanaIconMetadata,
  AssetsIconMetadata,
  AttachmentsIconMetadata,
  AuthenticationIconMetadata,
  AuthorizationIconMetadata,
  AwsIconMetadata,
  AzureDevOpsIconMetadata,
  AzureDevOpsRepositoryIconMetadata,
  AzureDevOpsWikiIconMetadata,
  AzureDevOpsWorkItemIconMetadata,
  AzureIconMetadata,
  BasecampIconMetadata,
  BeatIconMetadata,
  BellIconMetadata,
  BinaryTreeIconMetadata,
  BitbucketIconMetadata,
  BuildingIconMetadata,
  BulbIconMetadata,
  BusinessLogicIconMetadata,
  CIconMetadata,
  CalculatorIconMetadata,
  CalendarIconMetadata,
  CancelIconMetadata,
  CancelOutlineIconMetadata,
  CanvaIconMetadata,
  CheckIconMetadata,
  CheckOutlineIconMetadata,
  ChevronDownIconMetadata,
  ChevronLeftIconMetadata,
  ChevronRightIconMetadata,
  CircleIconMetadata,
  CircleOutlineIconMetadata,
  CisIconMetadata,
  ClassificationsIconMetadata,
  ClearAllIconMetadata,
  CloseIconMetadata,
  ClipboardIconMetadata,
  ClockIconMetadata,
  CloudIconMetadata,
  CloverLogoIconMetadata,
  CloverLogoWithTextIconMetadata,
  CloverOldLogoIconMetadata,
  CodeIconMetadata,
  ColorableAwsIconMetadata,
  ColorableCloverLogoIconMetadata,
  ColorableConfluenceIconMetadata,
  ColorableGoogleDriveIconMetadata,
  ColorableJiraIconMetadata,
  ColumnsIconMetadata,
  CommentIconMetadata,
  CommitIconMetadata,
  ConfluenceIconMetadata,
  ContentCopyIconMetadata,
  CPPIconMetadata,
  CryptographyIconMetadata,
  CSharpIconMetadata,
  CSSIconMetadata,
  DartIconMetadata,
  DataExchangeIconMetadata,
  DeleteIconMetadata,
  DenialOfServiceIconMetadata,
  DensityLargeIconMetadata,
  DensityMediumIconMetadata,
  DensitySmallIconMetadata,
  DesignDecisionIconMetadata,
  DockerIconMetadata,
  DocumentIconMetadata,
  DotsHorizontalIconMetadata,
  DotsHorizontalOutlineIconMetadata,
  DotsVerticalIconMetadata,
  DragHandleIconMetadata,
  DrawioIconMetadata,
  DropboxIconMetadata,
  DumbleIconMetadata,
  EditIconMetadata,
  ElevationOfPrivilegeIconMetadata,
  ErrorIconMetadata,
  ExpandAllIconMetadata,
  ExternalLinkIconMetadata,
  EyeIconMetadata,
  EyeOffIconMetadata,
  FedRampIconMetadata,
  FiberyIconMetadata,
  FileIconMetadata,
  FilledCheckSquareIconMetadata,
  FilledClipboardIconMetadata,
  FilledFlashIconMetadata,
  FigmaIconMetadata,
  FilterFunnelIconMetadata,
  FilterIconMetadata,
  FilterOffIconMetadata,
  FingerprintIconMetadata,
  FirstPageIconMetadata,
  FlashIconMetadata,
  FrameworkIconMetadata,
  GenAIIconMetadata,
  GithubIconMetadata,
  GitlabIconMetadata,
  GmailIconMetadata,
  GoIconMetadata,
  GoogleCloudIconMetadata,
  GoogleDriveIconMetadata,
  GrafanaIconMetadata,
  GranularIconMetadata,
  GraphIconMetadata,
  GridIconMetadata,
  GroovyIconMetadata,
  GroupByIconMetadata,
  HoverIconMetadata,
  HTMLIconMetadata,
  IecIconMetadata,
  ImageFileIconMetadata,
  InfoDisclosureIconMetadata,
  InfoIconMetadata,
  InfrastructureIconMetadata,
  InputFlowIconMetadata,
  JavaIconMetadata,
  JavaScriptIconMetadata,
  JiraBugIconMetadata,
  JiraEpicIconMetadata,
  JiraIconMetadata,
  JiraStoryIconMetadata,
  JiraTaskIconMetadata,
  JsonIconMetadata,
  KeyIconMetadata,
  KibanaIconMetadata,
  KotlinIconMetadata,
  KubernetesIconMetadata,
  LastPageIconMetadata,
  LayersIconMetadata,
  LinearIconMetadata,
  LinkIconMetadata,
  LocateIconMetadata,
  LockIconMetadata,
  LookerIconMetadata,
  LuaIconMetadata,
  LucidchartIconMetadata,
  MarkdownIconMetadata,
  MatlabIconMetadata,
  MattermostIconMetadata,
  MicrosoftExcelIconMetadata,
  MicrosoftWordIconMetadata,
  MinimizeIconMetadata,
  MinusIconMetadata,
  MiroIconMetadata,
  ModifiedIconMetadata,
  MondayIconMetadata,
  MoonIconMetadata,
  NistIconMetadata,
  HitrustIconMetadata,
  NotionIconMetadata,
  ObjectiveCIconMetadata,
  OneDriveIconMetadata,
  OutlineInfoIconMetadata,
  OutlineQuestionIconMetadata,
  OwaspIconMetadata,
  PackageFocusIconMetadata,
  PackageIconMetadata,
  PagerDutyIconMetadata,
  PciIconMetadata,
  PencilOutlineIconMetadata,
  PerlIconMetadata,
  PhpIconMetadata,
  PIIIconMetadata,
  PlayPauseIconMetadata,
  PlusIconMetadata,
  PostmanIconMetadata,
  PowerPointIconMetadata,
  PowerShellIconMetadata,
  PullRequestIconMetadata,
  PushPinIconMetadata,
  PythonIconMetadata,
  QuipIconMetadata,
  RIconMetadata,
  ReactIconMetadata,
  RecommendationIconMetadata,
  RefreshIconMetadata,
  ReloadIconMetadata,
  RemoveIconMetadata,
  RepositoryIconMetadata,
  RepudiationIconMetadata,
  RequirementsAnalysisIconMetadata,
  ResuggestIconMetadata,
  ReverseArrowIconMetadata,
  RubyIconMetadata,
  RustIconMetadata,
  SalesforceIconMetadata,
  SaveIconMetadata,
  ScalaIconMetadata,
  ScanIconMetadata,
  SearchIconMetadata,
  SearchOffIconMetadata,
  SecurityAnalysisIconMetadata,
  SendIconMetadata,
  SendTiltDownwardsIconMetadata,
  SendTiltUpwardsIconMetadata,
  SensitiveInfoIconMetadata,
  ServerIconMetadata,
  ServiceNowIconMetadata,
  ServiceTitanIconMetadata,
  SettingsIconMetadata,
  SharePointIconMetadata,
  ShellIconMetadata,
  ShieldIconMetadata,
  SlackIconMetadata,
  SmartsheetIconMetadata,
  SortIconMetadata,
  SpeedometerIconMetadata,
  SpoofingIconMetadata,
  SQLIconMetadata,
  SuccessIconMetadata,
  SunIconMetadata,
  SwapHorizontalIconMetadata,
  SwiftIconMetadata,
  TableauIconMetadata,
  TagsIconMetadata,
  TargetIconMetadata,
  TeamsIconMetadata,
  TerraformIconMetadata,
  TamperingIconMetadata,
  TextIconMetadata,
  ThumbsDownIconMetadata,
  ThumbsUpIconMetadata,
  TicketIconMetadata,
  TomlIconMetadata,
  TrelloIconMetadata,
  TypeScriptIconMetadata,
  UnknownIconMetadata,
  UmbrellaIconMetadata,
  UsersIconMetadata,
  ViolationAttentionIconMetadata,
  VisualBasicIconMetadata,
  VueIconMetadata,
  WarningIconMetadata,
  WarningOutlineIconMetadata,
  WeaknessIconMetadata,
  WhimsicalIconMetadata,
  YamlIconMetadata,
  ZendeskIconMetadata,
  ZoomIconMetadata,
];
