// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const GmailIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 16 16" ms="-1px" {...props} ref={ref} fill="none">
      <g id="Gmail">
        <path
          id="Vector"
          d="M15.0003 5.40002L13.3337 6.31669L11.667 7.90002V13.3334H14.0003C14.5527 13.3334 15.0003 12.8857 15.0003 12.3334V5.40002Z"
          fill="#4CAF50"
        />

        <path
          id="Vector_2"
          d="M1 5.40002L2.20467 5.97002L4.33333 7.90002V13.3334H2C1.44767 13.3334 1 12.8857 1 12.3334V5.40002Z"
          fill="#1E88E5"
        />

        <path
          id="Vector_3"
          d="M11.6667 3.73328L8 6.48328L4.33333 3.73328L4 5.66661L4.33333 7.89994L8 10.6499L11.6667 7.89994L12 5.66661L11.6667 3.73328Z"
          fill="#E53935"
        />

        <path
          id="Vector_4"
          d="M1 4.09929V5.39996L4.33333 7.89996V3.73329L3.292 2.95296C3.044 2.76696 2.74267 2.66663 2.43267 2.66663C1.64133 2.66663 1 3.30796 1 4.09929Z"
          fill="#C62828"
        />

        <path
          id="Vector_5"
          d="M15.0003 4.09929V5.39996L11.667 7.89996V3.73329L12.7083 2.95296C12.9563 2.76696 13.2577 2.66663 13.5677 2.66663C14.359 2.66663 15.0003 3.30796 15.0003 4.09929Z"
          fill="#FBC02D"
        />
      </g>
    </Icon>
  );
});

export const GmailIconMetadata = {
  name: 'Gmail Logo',
  importName: 'GmailIcon',
  component: <GmailIcon aria-label="Gmail Icon" />,
  tags: 'gmail, email, google, send, message',
};
