// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const SendTiltDownwardsIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <path
        d="M1.15316 9.92153C1.15316 9.92153 1.15316 9.91684 1.15316 9.9145L3.88129 0.917313C3.92258 0.771179 4.00758 0.641171 4.12488 0.544731C4.24219 0.448293 4.38618 0.390037 4.53754 0.377781C4.5591 0.375906 4.58066 0.374969 4.60223 0.374969C4.74407 0.374528 4.88308 0.414676 5.00285 0.490674C5.12262 0.566672 5.21815 0.675348 5.27816 0.803875L6.98441 4.30544C7.00149 4.34053 7.00718 4.38007 7.00071 4.41856C6.99424 4.45704 6.97593 4.49255 6.94832 4.52012L4.23332 7.23513C4.16596 7.30602 4.12897 7.40043 4.13022 7.49822C4.13147 7.596 4.17087 7.68943 4.24002 7.75858C4.30917 7.82773 4.4026 7.86713 4.50039 7.86838C4.59817 7.86964 4.69258 7.83264 4.76348 7.76528L7.47988 5.05028C7.50746 5.02267 7.54297 5.00436 7.58145 4.99789C7.61993 4.99142 7.65948 4.99712 7.69457 5.01419L11.1994 6.71997C11.3365 6.78572 11.4503 6.89157 11.5258 7.02348C11.6014 7.15539 11.6351 7.30714 11.6224 7.45863C11.6097 7.61011 11.5513 7.75417 11.455 7.87172C11.3586 7.98926 11.2288 8.07475 11.0827 8.11684L2.08551 10.845H2.07847C1.95037 10.881 1.815 10.8822 1.68625 10.8486C1.5575 10.815 1.44001 10.7478 1.34583 10.6538C1.25164 10.5598 1.18416 10.4424 1.1503 10.3138C1.11645 10.1851 1.11743 10.0497 1.15316 9.92153Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const SendTiltDownwardsIconMetadata = {
  name: 'Send Tilt Downwards',
  importName: 'SendTiltDownwardsIcon',
  component: <SendTiltDownwardsIcon aria-label="Send Tilt Downwards Icon" />,
  tags: 'send, message, post, chat, delivery, communication, tilt, downwards',
};
