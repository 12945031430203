// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const FilledCheckSquareIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 256 256" {...props} ref={ref} fill="currentColor">
      <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm-34.34,77.66-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z" />
    </Icon>
  );
});

export const FilledCheckSquareIconMetadata = {
  name: 'Filled Check Square',
  importName: 'FilledCheckSquareIcon',
  component: <FilledCheckSquareIcon aria-label="Filled Check Square Icon" />,
  tags: 'filled, check, square',
};
