// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const FilledFlashIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 256 256" {...props} ref={ref} fill="currentColor">
      <path d="M213.85,125.46l-112,120a8,8,0,0,1-13.69-7l14.66-73.33L45.19,143.49a8,8,0,0,1-3-13l112-120a8,8,0,0,1,13.69,7L153.18,90.9l57.63,21.61a8,8,0,0,1,3,12.95Z" />
    </Icon>
  );
});

export const FilledFlashIconMetadata = {
  name: 'Filled Flash',
  importName: 'FilledFlashIcon',
  component: <FilledFlashIcon aria-label="Filled Flash Icon" />,
  tags: 'flash, attack, security, filled',
};
