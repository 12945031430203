// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const VisualBasicIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 128 128" {...props} ref={ref}>
      <path
        d="M128 63.121a63.121 63.121 0 01-63.121 63.122A63.121 63.121 0 011.757 63.121 63.121 63.121 0 0164.879 0 63.121 63.121 0 01128 63.121z"
        fill="#004e8c"
      />

      <path d="M21.127 17.753a63.121 63.121 0 1089.12 89.12z" opacity=".1" fill="#fff" />

      <path
        d="M61.17 38.168L47.125 77.619a21.698 21.698 0 00-1.104 4.498h-.138a23.414 23.414 0 00-1.006-4.398L31.068 38.268H24.58l18.03 49.709h6.488l18.326-49.71-6.254-.099zm13.414.1v49.709h14.44a17.043 17.043 0 0011.538-3.946 13 13 0 004.577-10.336 11.677 11.677 0 00-3.018-8.345 12.644 12.644 0 00-8.049-3.787v-.137a12.348 12.348 0 006.313-4.44 12.092 12.092 0 002.347-7.338 10.198 10.198 0 00-3.787-8.224 15.465 15.465 0 00-10.218-3.156H74.584zm12.525 5.187c6.352 0 9.528 2.414 9.528 7.24a8.028 8.028 0 01-2.742 6.508 11.322 11.322 0 01-7.477 2.367h-5.916V43.514l6.607-.059zM80.502 64.8l6.607.02c7.996 0 11.993 2.931 11.993 8.796a8.304 8.304 0 01-2.82 6.649 11.835 11.835 0 01-7.891 2.425h-7.889V64.8z"
        fill="#fff"
      />
    </Icon>
  );
});

export const VisualBasicIconMetadata = {
  name: 'VisualBasic',
  importName: 'VisualBasicIcon',
  component: <VisualBasicIcon aria-label="VisualBasic Icon" />,
  tags: 'visual, basic, programming, language, logo, application',
};
