// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const TomlIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 512 512" {...props} ref={ref}>
      <path d="m512 511.99955h-114.53076v-56.58646h52.66809v-398.55357h-52.66809v-56.859506h114.53076zm-136.11498-404.25698v59.34432h-91.16149v275.87982h-64.10614v-275.87982h-91.58626v-59.34432zm-375.88502-107.74212h113.96325v56.586452h-52.668085v398.55358h52.668085v56.85952h-113.96325z" />
    </Icon>
  );
});

export const TomlIconMetadata = {
  name: 'Toml',
  importName: 'TomlIcon',
  component: <TomlIcon aria-label="Yaml Icon" />,
  tags: 'toml, programming, language, logo, application',
};
