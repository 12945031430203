// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const PlayPauseIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 256 256" {...props} ref={ref} fill="currentColor">
      <path d="M184,64V192a8,8,0,0,1-16,0V64a8,8,0,0,1,16,0Zm40-8a8,8,0,0,0-8,8V192a8,8,0,0,0,16,0V64A8,8,0,0,0,224,56Zm-87.33,58.66L48.48,58.51A15.91,15.91,0,0,0,24,71.85v112.3A15.83,15.83,0,0,0,32.23,198a15.95,15.95,0,0,0,16.25-.53l88.19-56.15a15.8,15.8,0,0,0,0-26.68Z" />
    </Icon>
  );
});

export const PlayPauseIconMetadata = {
  name: 'Play Pause',
  importName: 'PlayPauseIcon',
  component: <PlayPauseIcon aria-label="Play Pause Icon" />,
  tags: 'play, pause, start, stop, play-pause, play-pause, wait, continue, resume',
};
