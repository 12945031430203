// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const SendTiltUpwardsIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 12 12" {...props} ref={ref} fill="none">
      <path
        d="M10.8468 2.07847C10.8468 2.07847 10.8468 2.08316 10.8468 2.0855L8.11871 11.0827C8.07742 11.2288 7.99242 11.3588 7.87512 11.4553C7.75781 11.5517 7.61382 11.61 7.46246 11.6222C7.4409 11.6241 7.41934 11.625 7.39777 11.625C7.25593 11.6255 7.11692 11.5853 6.99715 11.5093C6.87738 11.4333 6.78185 11.3247 6.72184 11.1961L5.01559 7.69456C4.99851 7.65947 4.99282 7.61993 4.99929 7.58144C5.00576 7.54296 5.02407 7.50745 5.05168 7.47988L7.76668 4.76487C7.83404 4.69398 7.87103 4.59957 7.86978 4.50178C7.86853 4.404 7.82913 4.31057 7.75998 4.24142C7.69083 4.17227 7.5974 4.13287 7.49961 4.13162C7.40183 4.13036 7.30742 4.16736 7.23652 4.23472L4.52012 6.94972C4.49254 6.97733 4.45703 6.99564 4.41855 7.00211C4.38007 7.00858 4.34052 7.00288 4.30543 6.98581L0.800587 5.28003C0.663533 5.21428 0.549695 5.10843 0.474157 4.97652C0.398619 4.84461 0.364948 4.69286 0.377605 4.54137C0.390262 4.38989 0.448651 4.24583 0.545033 4.12828C0.641415 4.01074 0.771241 3.92525 0.917305 3.88316L9.91449 1.15503H9.92153C10.0496 1.11904 10.185 1.11778 10.3137 1.15137C10.4425 1.18497 10.56 1.25221 10.6542 1.34621C10.7484 1.4402 10.8158 1.55756 10.8497 1.68624C10.8836 1.81492 10.8826 1.95029 10.8468 2.07847Z"
        fill="currentColor"
      />
    </Icon>
  );
});

export const SendTiltUpwardsIconMetadata = {
  name: 'Send Tilt Upwards',
  importName: 'SendTiltUpwardsIcon',
  component: <SendTiltUpwardsIcon aria-label="Send Tilt Upwards Icon" />,
  tags: 'send, message, post, chat, delivery, communication, tilt, upwards',
};
