// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const YamlIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 128 128" {...props} ref={ref}>
      <polygon
        transform="matrix(.24805 0 0 .24805 .5 5.6287)"
        points="87.702 137.67 0 0 63.25 0 119.02 88.646 175.24 0 235.79 0 143.98 137.67 143.98 224.95 87.702 224.95"
      />

      <path
        d="m82.428 49.149h-25.266l-5.1388 12.408h-11.188l23.659-55.798h11.444l22.699 55.798h-11.956l-4.2525-12.408zm-4.197-11.14-7.7455-20.476-8.6412 20.476z"
        fill="#cb171e"
      />

      <polygon
        transform="matrix(.24805 0 0 .24805 .5 5.6287)"
        points="87.701 250.18 87.701 470.65 135 470.65 135 318.57 184.51 420.79 221.74 420.79 272.94 314.98 272.94 470.6 318.32 470.6 318.32 250.18 256.36 250.18 201.38 349.88 149.02 250.18"
      />

      <polygon
        transform="matrix(.24805 0 0 .24805 .5 5.6287)"
        points="512 422.74 512 422.74 395.64 422.74 395.64 250.12 347.44 250.12 347.44 469.65 512 469.65"
      />
    </Icon>
  );
});

export const YamlIconMetadata = {
  name: 'Yaml',
  importName: 'YamlIcon',
  component: <YamlIcon aria-label="Yaml Icon" />,
  tags: 'yaml, programming, language, logo, application',
};
