// 3rd
import { forwardRef } from '@chakra-ui/react';

// App - Types
import type { IconProps } from './icon';

// App - Other
import { Icon } from './icon';

export const IecIcon = forwardRef((props: IconProps, ref) => {
  return (
    <Icon viewBox="0 0 400 400" {...props} ref={ref} fill="none">
      <path d="m0 400h400v-400h-400z" fill="currentColor" />

      <g fill="#fff">
        <path d="m76.201 86.521h-32.884v162.03h32.884z" />

        <path d="m218.56 82.719h-80.086c-7.7143 0-19.12 8.6576-19.12 20.867v118.79c0 9.6844 8.797 25.917 23.865 25.917h76.895v-13.68h-54.556c-9.6569 0-14.263-4.6618-14.263-10.545v-44.315c0-4.634-1.9703-12.237 3.8297-12.237h47.979v-12.237h-46.814c-6.1879 0-4.995-4.4398-4.995-8.2136v-37.35c0-4.2178-0.13854-11.488 7.9919-11.488h59.274z" />

        <path d="m359.78 109.72s-17.815-30.385-58.052-30.385c-32.855 0-75.813 30.801-75.813 87.77 0 62.019 48.729 84.801 78.144 84.801 44.872 0 61.133-41.762 61.133-41.762l-7.7421-7.187s-20.618 36.934-51.365 36.934c-18.953 0-49.228-18.786-49.228-72.785 0-59.938 25.641-75.116 49.228-75.116 29.026 0 45.565 26.167 45.565 26.167z" />

        <path d="m338.83 334.48c12.515 0 22.672-10.6 22.672-23.697 0-13.098-10.157-23.725-22.672-23.725-12.543 0-22.672 10.628-22.672 23.725 0 13.097 10.128 23.697 22.672 23.697" />

        <path d="m43.317 326.63v6.8539l271.53 0.11082c-1.9425-2.0534-3.5521-4.3843-4.9672-6.8539zm262.74-20.257h-262.74v7.7974h262.62c-0.1119-1.1377-0.33033-2.2199-0.33033-3.3853 0-1.5262 0.2504-2.9692 0.44218-4.4121m-262.74-18.398v6.965h266.37s0.16518-0.22217 0.44222-0.555c1.332-2.3032 2.8585-4.4953 4.6902-6.41z" />
      </g>
    </Icon>
  );
});

export const IecIconMetadata = {
  name: 'Iec Logo',
  importName: 'IecIcon',
  component: <IecIcon aria-label="IEC Logo Icon" />,
  tags: 'iec, security, frameworks, industry, standards, compliance, logo, brand, company, org, organization',
};
